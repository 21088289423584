export const HeartSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 21.0938C12.5 21.0938 2.73438 15.625 2.73438 8.98438C2.73438 7.81044 3.14111 6.67276 3.88537 5.76491C4.62964 4.85705 5.66546 4.2351 6.8166 4.00487C7.96774 3.77464 9.16309 3.95036 10.1993 4.50213C11.2355 5.05389 12.0485 5.94762 12.5 7.03126V7.03126C12.9515 5.94762 13.7645 5.05389 14.8007 4.50213C15.8369 3.95036 17.0323 3.77464 18.1834 4.00487C19.3345 4.2351 20.3704 4.85705 21.1146 5.76491C21.8589 6.67276 22.2656 7.81044 22.2656 8.98438C22.2656 15.625 12.5 21.0938 12.5 21.0938Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
