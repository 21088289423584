export const PhoneSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0332 12.1874C9.83713 13.8476 11.1796 15.1866 12.8418 15.9863C12.9644 16.0443 13.1 16.0695 13.2353 16.0592C13.3706 16.0489 13.5008 16.0036 13.6133 15.9277L16.0547 14.2968C16.1626 14.2237 16.2872 14.179 16.417 14.1671C16.5468 14.1551 16.6775 14.1762 16.7969 14.2285L21.3672 16.1913C21.5234 16.2564 21.6539 16.3709 21.7386 16.5174C21.8233 16.6638 21.8575 16.8341 21.8359 17.0019C21.6911 18.1325 21.1392 19.1716 20.2836 19.9247C19.428 20.6779 18.3273 21.0934 17.1875 21.0937C13.6651 21.0937 10.287 19.6944 7.79624 17.2037C5.30552 14.713 3.90625 11.3348 3.90625 7.81244C3.90651 6.67259 4.32208 5.57189 5.07522 4.7163C5.82836 3.86071 6.86744 3.30886 7.99805 3.164C8.16586 3.14245 8.3361 3.17665 8.48256 3.26135C8.62902 3.34605 8.74358 3.47655 8.80859 3.63275L10.7715 8.21283C10.8225 8.33036 10.8439 8.45864 10.8337 8.58636C10.8234 8.71409 10.782 8.83734 10.7129 8.94525L9.08203 11.4257C9.00942 11.5379 8.96681 11.6669 8.95826 11.8002C8.94971 11.9336 8.97552 12.0669 9.0332 12.1874V12.1874Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
