export const ArrowUpSvg = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.85355 0.146447C4.65829 -0.0488155 4.34171 -0.0488155 4.14645 0.146447L0.964466 3.32843C0.769204 3.52369 0.769204 3.84027 0.964466 4.03553C1.15973 4.2308 1.47631 4.2308 1.67157 4.03553L4.5 1.20711L7.32843 4.03553C7.52369 4.2308 7.84027 4.2308 8.03553 4.03553C8.2308 3.84027 8.2308 3.52369 8.03553 3.32843L4.85355 0.146447ZM4 0.5L4 10H5L5 0.5L4 0.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
