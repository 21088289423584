export const InstagramSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 16.4062C14.6574 16.4062 16.4062 14.6574 16.4062 12.5C16.4062 10.3426 14.6574 8.59375 12.5 8.59375C10.3426 8.59375 8.59375 10.3426 8.59375 12.5C8.59375 14.6574 10.3426 16.4062 12.5 16.4062Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7969 3.51562H8.20312C5.61429 3.51562 3.51562 5.61429 3.51562 8.20312V16.7969C3.51562 19.3857 5.61429 21.4844 8.20312 21.4844H16.7969C19.3857 21.4844 21.4844 19.3857 21.4844 16.7969V8.20312C21.4844 5.61429 19.3857 3.51562 16.7969 3.51562Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5781 8.39844C18.1175 8.39844 18.5547 7.96122 18.5547 7.42188C18.5547 6.88253 18.1175 6.44531 17.5781 6.44531C17.0388 6.44531 16.6016 6.88253 16.6016 7.42188C16.6016 7.96122 17.0388 8.39844 17.5781 8.39844Z"
        fill="currentColor"
      />
    </svg>
  );
};
