export const ToteSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3906 21.0938H4.60939C4.41716 21.0928 4.23183 21.022 4.08804 20.8944C3.94426 20.7668 3.85187 20.5912 3.82814 20.4004L2.44142 7.90039C2.42909 7.79148 2.43981 7.68119 2.47289 7.57669C2.50598 7.4722 2.56067 7.37583 2.63344 7.29386C2.7062 7.21189 2.7954 7.14614 2.89523 7.1009C2.99506 7.05565 3.1033 7.03192 3.21291 7.03125H21.7871C21.8967 7.03192 22.005 7.05565 22.1048 7.1009C22.2046 7.14614 22.2938 7.21189 22.3666 7.29386C22.4394 7.37583 22.4941 7.4722 22.5271 7.57669C22.5602 7.68119 22.5709 7.79148 22.5586 7.90039L21.1719 20.4004C21.1482 20.5912 21.0558 20.7668 20.912 20.8944C20.7682 21.022 20.5829 21.0928 20.3906 21.0938V21.0938Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.59375 10.1562V7.03125C8.59375 5.99525 9.0053 5.00168 9.73786 4.26911C10.4704 3.53655 11.464 3.125 12.5 3.125C13.536 3.125 14.5296 3.53655 15.2621 4.26911C15.9947 5.00168 16.4062 5.99525 16.4062 7.03125V10.1562"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
